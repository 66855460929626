@import '../../mixins';

.mainContainer {
  width: 100%;
  min-height: 80vh;
  padding: 40px;
  background-color: #fefeff;
  @include mobile {
    padding: 16px;
  }

  &_main {
    width: 100%;
    min-height: calc(100vh - 80px);
    height: 100%;
    background-image: url('/assets/Images/login_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    display: flex;
    position: relative;
    @include mobile {
      min-height: auto;
      background-image: none;
      flex-direction: column;
      padding: 16px;
    }
    &_logo {
      padding: 22px;
      @include mobile {
        padding: 0;
        width: 100%;
        @include center-between;
        padding-bottom: 10px;
      }
      &_register {
        padding: 8px 24px;
        color: var(--primary);
        background-color: var(--primary-light);
        outline: none;
        height: 36px;
        width: max-content;
        border: none;
        border-radius: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      img {
        cursor: pointer;
        @include mobile {
          width: 125px;
          height: 28px;
        }
      }
    }
    &_details {
      position: absolute;
      right: 104px;
      top: 104px;
      padding: 40px;
      width: 468px;
      height: 532px;
      border-radius: 40px;
      background-color: $--card-main;
      top: calc(50% - 532px / 2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        position: static;
        width: 100%;
        padding: 0;
        gap: 10px;
        justify-content: flex-start;
        height: 100%;
        flex-grow: 1;
      }
      &_header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        h1 {
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 34px;
          color: $--main-text;
        }
        span {
          display: flex;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          align-items: center;
          gap: 8px;
          color: $--main-text;
          a {
            color: $--primary;
            font-weight: 500;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &_main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        &_inputs {
          display: flex;
          flex-direction: column;
          gap: 5px;
          position: relative;
          min-height: 78px;
          width: 100%;
          label {
            color: $--main-text;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 4px;
            @include center-between;
          }
          &_selects {
            color: #000;
          }
          &_ibutton {
            color: #fff !important;
            input {
              color: #fff !important;
            }
          }
          &_border {
            width: 100%;
            border-radius: 48px;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
          }
          input {
            border-radius: 48px;
            width: 100%;
            height: max-content;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            outline: none;
            border: none;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
            line-height: 20px;
            color: $--main-text;
            padding: 12px 24px;
            &::placeholder {
              color: $--sec-text;
              font-weight: 400;
            }
          }
          nav {
            position: absolute;
            right: 12px;
            top: calc(50% - 20px / 2);
            cursor: pointer;
          }
          span {
            color: $--primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
        &_span {
          cursor: pointer;
          color: $--primary;
          align-self: flex-end;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          text-align: right;

          /* red/main red */
        }
      }
      &_footer {
        justify-self: flex-end;

        width: 100%;
        @include mobile {
          margin-top: 2px;
        }
        div {
          @include center;
        }
      }
    }
  }
}

.registerMainContainer {
  width: 100%;
  min-height: 100vh;
  padding: 30px;
  background-color: #fefeff;
  @include mobile {
    padding: 16px;
  }

  &_main {
    width: 100%;
    min-height: 100vh;
    height: 100%;

    background-image: url('/assets/Images/register_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    display: flex;
    position: relative;
    @include mobile {
      padding: 16px;
      background-image: none;
      flex-direction: column;
    }
    &_logo {
      padding: 22px;
      @include mobile {
        padding: 0;
        width: 100%;
        @include center-between;
        padding-bottom: 10px;
      }
      &_login {
        padding: 8px 24px;
        color: var(--primary);
        background-color: var(--primary-light);
        outline: none;
        height: 36px;
        width: 94px;
        border: none;
        border-radius: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      img {
        cursor: pointer;
        @include mobile {
          width: 125px;
          height: 28px;
        }
      }
    }
    &_details {
      position: absolute;
      width: 690px;
      min-height: 600px;
      height: max-content;
      right: 50px;
      top: 40px;
      margin-bottom: 50px;

      padding: 24px;
      border-radius: 40px;
      background-color: $--card-main;
      gap: 25px;
      display: flex;
      flex-direction: column;

      @include mobile {
        position: static;
        width: 100%;
        padding: 0;
        gap: 10px;
        justify-content: flex-start;
        height: 100%;
        flex-grow: 1;
        transform: none;
      }
      &_head {
        display: flex;
        flex-direction: column;
        gap: 0;
        &_top {
          width: 100%;
          padding: 4px 0;
          @include center-between;
          @include mobile {
            width: 100%;
            flex-direction: column-reverse;
            align-items: baseline;
            gap: 20px;
          }
          div {
            min-width: 180px;
            @include center-between;
            @include mobile {
              width: 100%;
              min-width: 100%;
            }
            div {
              border-radius: 10px;
              @include mobile {
                min-width: 125%;
                padding: 0 !important;
              }
            }
            span {
              height: 6px;
              border-radius: 10px;
            }
            p {
              margin-left: -100px;
              @include mobile {
                margin-left: -120%;
                display: block !important;
              }
            }
          }
          h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            color: $--main-text;
            @include mobile {
              font-size: 22px;
              line-height: 27px;
            }
          }
        }
        p {
          font-style: normal;
          display: flex;
          align-items: center;
          gap: 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $--sec-text;
          a {
            color: $--primary;
            font-weight: 500;
            &:hover {
              text-decoration: underline;
            }
          }
          @include mobile {
            display: none;
          }
        }
      }
      &_form {
        display: flex;

        gap: 20px;
        width: 100%;
        flex-wrap: wrap;
        background-color: $--card-main;
        h2 {
          font-weight: 600;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          @include mobile {
            font-size: 16px;
            line-height: 20px;
            color: $--main-text;
          }
        }
        &_radios {
          width: 100%;
        }
        &_inputs {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;
          position: relative;

          min-height: 78px;
          label {
            color: $--main-text;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 4px;
            @include center-between;
          }
          &_border {
            width: 100%;
            border-radius: 48px;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
          }
          input {
            border-radius: 48px;
            width: 100%;
            height: max-content;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            outline: none;
            border: none;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
            line-height: 20px;
            color: $--main-text;
            padding: 12px 24px;
            &::placeholder {
              color: $--sec-text;
              font-weight: 400;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            &[type='number'] {
              -moz-appearance: textfield; /* Firefox */
            }
          }
          textarea {
            border-radius: 18px;
            width: 100%;
            height: max-content;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            outline: none;
            border: none;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
            line-height: 20px;
            color: $--main-text;
            padding: 12px 24px;
            &::placeholder {
              color: $--sec-text;
              font-weight: 400;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            &[type='number'] {
              -moz-appearance: textfield; /* Firefox */
            }
          }
          nav {
            position: absolute;
            right: 12px;
            top: calc(50% - 20px / 2);
            cursor: pointer;
          }
          span {
            color: $--primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
        &_inputsmal {
          width: 48%;

          display: flex;
          flex-direction: column;
          gap: 4px;
          position: relative;
          min-height: 78px;
          @include mobile {
            width: 100%;
          }
          &_border {
            width: 100%;
            border-radius: 48px;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
          }
          input {
            border-radius: 48px;
            width: 100%;
            height: max-content;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            outline: none;
            border: none;

            line-height: 20px;
            color: $--main-text;
            padding: 12px 24px;
            &::placeholder {
              color: $--sec-text;
              font-weight: 400;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            &[type='number'] {
              -moz-appearance: textfield; /* Firefox */
            }
          }
          span {
            color: $--primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
        &_inputsmall {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 48%;
          position: relative;
          min-height: 78px;
          @include mobile {
            width: 100%;
          }
          label {
            color: $--main-text;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 4px;
            @include center-between;
          }
          &_border {
            width: 100%;
            border-radius: 48px;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
          }
          &_nb {
            width: 100%;

            section {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              aside {
                display: flex;
                width: max-content;
                padding: 4px 6px;
                border-radius: 10px;
                border: 1px solid $--primary;
                align-items: center;
                gap: 4px;
                span {
                  font-size: 14px;
                  &:last-of-type {
                    @include center;
                    background-color: $--primary;
                    height: 15px;
                    width: 15px;
                    border-radius: 5px;
                    cursor: pointer;
                    svg {
                      height: 10px;
                      width: 10px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          input {
            border-radius: 48px;
            width: 100%;
            height: max-content;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            outline: none;
            border: none;
            box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
            line-height: 20px;
            color: $--main-text;
            padding: 12px 24px;
            &::placeholder {
              color: $--sec-text;
              font-weight: 400;
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            &[type='number'] {
              -moz-appearance: textfield; /* Firefox */
            }
          }
          nav {
            position: absolute;
            right: 12px;
            top: calc(50% - 20px / 2);
            cursor: pointer;
          }
          span {
            color: $--primary;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }
}

.MainContainer {
  display: flex;

  width: 100%;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;

  padding-bottom: 50px;
  &_subContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: center;
    max-width: 720px;
    width: 100%;
    background-color: $--card-main;
    // padding: 10px  8rem;
    // border: 1px solid gray;
    @include mobile {
      // padding: 0 15px;
      border: none;
      width: 100%;
      max-width: 100%;
    }
    &_savebutton {
      background-color: #d12d34;
      margin-top: 10px;
      width: max-content;
      height: 40px;
      border: none;
      align-self: center;
      padding: 0 20px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 1.2rem;
    }
    &_heading {
      color: $--main-text;
      text-align: start;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      @include mobile {
      }
    }
    &_box {
      width: 100%;

      margin: 10px 0;

      background-color: transparent;

      display: flex;
      gap: 8px;
      color: $--main-text;
      flex-wrap: wrap;
      section {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
        gap: 20px;
        aside {
          display: flex;
          width: max-content;
          padding: 4px 6px;
          border-radius: 10px;
          border: 1px solid $--primary;
          align-items: center;
          gap: 4px;
          span {
            font-size: 14px;
            &:last-of-type {
              @include center;
              background-color: $--primary;
              height: 15px;
              width: 15px;
              border-radius: 5px;
              cursor: pointer;
              svg {
                height: 10px;
                width: 10px;
                color: #fff;
              }
            }
          }
        }
      }
      &_heading {
        color: $--main-text;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        width: 100%;
      }
      &_headings {
        width: 100%;
        color: $--main-text;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
      &_left {
        width: 48%;
        display: flex;
        flex-direction: column;
        @include mobile {
          width: 100%;
        }
      }
      &_right {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 2px;
        display: flex;
        flex-direction: column;
        @include mobile {
          width: 100%;
        }
      }
      hr {
        width: 100%;
        border-top: none;
      }
      &_checkbox {
        input {
          border: 1px solid red !important;
        }
      }
      &_addbutton {
        width: 57px !important;
        height: 47px !important;
        border: 1px solid #000 !important;
        background-color: #fff !important;
      }
      &_textarea {
        height: auto;
        background-color: #000;
        color: $--main-text;
        padding: 8px;
        border-radius: 5px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          opacity: 0.8;
        }
      }
      &_selects {
        width: 100%;
        height: 47px !important;
        border-radius: 5px !important;
        border: solid 1px #fff !important;
        background-color: #000 !important;
        color: $--main-text !important;
        padding: 0 8px;
        &::placeholder {
          opacity: 0.8 !important;
        }
        &:focus {
          outline: none;
        }
        svg {
          color: $--main-text;
        }
        select {
          color: $--main-text;
        }
        option {
          color: #000 !important;
        }
      }
      &_error {
        color: red;
        font-size: 0.9375rem;
      }
    }
  }
}

.profileUpdate {
  display: flex;
  padding: 25px;
  width: max-content;
  margin: 10px 0;
  margin-bottom: 40px;
  flex-direction: column;
  margin: auto;
  max-width: 600px;
  width: 100%;
  height: max-content;
  @include oval-shadow;
  @include mobile {
    max-width: 100%;
  }
  &_header {
    display: flex;
    justify-content: space-between;
    &_welcome {
      span {
        color: $--main-text;
      }
      h2 {
        color: $--primary;
        margin: 0;
      }
    }
    &_avatar {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  &_main {
    display: flex;
    margin: 15px 0;
    flex-direction: column;
    gap: 25px;
    &_inputs {
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: relative;
      min-height: 78px;
      width: 100%;
      &:focus {
        border: 1pxvsolid #d12d34;
      }
      label {
        color: $--main-text;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 4px;
        @include center-between;
      }
      &_selects {
        color: #000;
      }
      section {
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: flex-end;
      }
      &_ibutton {
        color: #fff !important;
        input {
          color: #fff !important;
        }
      }
      &_border {
        width: 100%;
        border-radius: 48px;
        box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
        position: relative;
        &_icons {
          position: absolute;
          right: 10px;
          bottom: 0;
          height: 100%;

          @include center;
          gap: 4px;
        }
      }
      input {
        border-radius: 48px;
        width: 100%;
        height: max-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        outline: none;
        border: none;
        box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12) !important;
        line-height: 20px;
        color: $--main-text;
        padding: 12px 24px;
        &::placeholder {
          color: $--sec-text;
          font-weight: 400;
        }
      }
      nav {
        position: absolute;
        right: 12px;
        top: calc(50% - 20px / 2);
        cursor: pointer;
      }
      span {
        color: $--primary;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    aside {
      display: flex;
      width: max-content;
      padding: 4px 6px;
      border-radius: 10px;
      border: 1px solid $--primary;
      align-items: center;
      gap: 4px;
      span {
        font-size: 14px;
        &:last-of-type {
          @include center;
          background-color: $--primary;
          height: 15px;
          width: 15px;
          border-radius: 5px;
          cursor: pointer;
          svg {
            height: 10px;
            width: 10px;
            color: #fff;
          }
        }
      }
    }
  }
}
